<template>
  <div class="facilities--field mb-2">
    <v-simple-checkbox
      @click="toggleFacility"
      :id="'facility-without-unit-field-' + id"
      :value="checked"
      :ripple="false"
    />
    <label :for="'facility-without-unit-field-' + id">{{ name }}</label>
  </div>
</template>

<script>
export default {
  name: 'facility-with-unit-field',
  props: ['name', 'checked', 'id'],
  methods: {
    toggleFacility() {
      let self = this;
      self.$store.commit('unitType/form/TOGGLE_CHECK_FACILITY', this.id);
    },
  },
};
</script>

<style scoped></style>
